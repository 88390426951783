.Login-component {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/*.DatePicker-input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}*/

.fa-map-marker-green {
  color: green;
}

.fa-map-marker-red {
  color: red;
}

.fa-map-marker-blue {
  color: blue;
}

.fa-map-marker-yellow {
  color: sandybrown;
}

.fa-map-marker-grey {
  color: grey;
}

.fa-link-blue{
    color: blue;
}

.fa-link-purple {
  color: rebeccapurple;
}

.fa-link-green {
  color: green;
}

.fa-link-orange {
  color: orangered;
}

.SlideModal {
  width: 70% !important
}

.card-header {
  background-color: #20a8d8 !important,
}


.removeOverflow {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.scroll-container {
  height: calc(85vh - 20px);
  /*height: 100%;*/
}

.fab-ankor {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
}

