:global {
  .modal-body {
    overflow-x: hidden;
  }

  .modal-header.bg-primary .close {
    color:white;
    opacity: .6;
    &:hover {
      opacity: .9;
    }
  }
}